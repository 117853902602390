<template>
  <div>
    <!-- DESKTOP -->
    <v-row class="mb-xl-formsanswers pl-11 pr-11">
      <v-col class="mt-14 pl-0 pr-0 text-left" cols="12">
        <h1 class="forms-answers__title">{{ title }}</h1>
      </v-col>
    </v-row>

    <!-- MOBILE -->

    <v-row class="mb-t-formsanswers px-0 mx-0 mt-5">
      <v-col cols="12" class="text-left">
        <div class="d-flex">
          <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
            <img width="15" src="../../assets/icon/navigate_before.png" />
          </v-btn>
          <p class="text-title-1">
            <label
              style="padding-top: 30%"
              class="text-apps-blue-sm txt-capitalize"
            >
              {{ name }},
            </label>
            <label style="padding-top: 30%" class="text-apps-blue-sm">
              está viendo
            </label>
          </p>
        </div>
        <p class="text-apps-sm mb-1 ml-9">
          {{ title }}
        </p>
      </v-col>
    </v-row>
    <!-- FORMULARIO -->
    <v-row
      class="mt-10 mx-10 px-0 px-0 px-sm-10 px-md-10 px-lg-10 px-xl-10 spacing-form-res"
    >
      <template v-for="(data, i) in listAnswer">
        <v-col
          :key="data.answer"
          style="text-align: initial"
          :class="i % 2 == 0 ? 'border-right-f' : ''"
          class="pl-0 pl-sm-12 pl-md-12 pl-lg-12 pl-xl-12"
          cols="12"
          md="6"
          xs="6"
          sm="6"
          lg="6"
          xl="6"
        >
          <label :class="data.question ? 'text-label-dark-o' : 'hidden'">
            {{ data.question }} :
          </label>
          <!-- <span :class="data.question ? 'text-label-dark-o' : 'hidden'>
            :
          </span> -->

          <template v-if="Array.isArray(data.answers) && (!data.name.includes('image-upload') && !data.name.includes('file-upload'))">
            <br :key="'a' + data.answer" />
            <br :key="'a1' + data.answer" />
            <template v-for="data1 in data.answers">
              <label :key="data1" class="text-label-o pl-8">
                <v-icon color="#466be3"> mdi-circle-medium </v-icon> {{ data1 }}
              </label>
              <br :key="'o' + data1" />
            </template>
          </template>

          <template v-else>
            <label class="text-label-o break-word">

              <template v-if="data.name.includes('file-upload') || data.name.includes('image-upload')">
                 Haz click
                <a @click="showDocument(data)"><b>aquí</b></a>
                para visualizar el archivo
              </template>
              <template v-else>
                {{ data.answers }}
              </template>

              <!-- <template
                v-if="data.answers == null || !data.answers.startsWith('http')"
              >
                {{ data.answers }}
              </template>
              <template v-else>
                Haz click
                <a @click="showDocument(data)"><b>aquí</b></a>
                para visualizar el archivo
              </template> -->
            </label>
          </template>
        </v-col>
      </template>
    </v-row>

    <!-- Tablet -->
    <!-- <v-row class="mt-10 mx-10 px-2 mb-t-formsanswers">
      <template v-for="(data, i) in listAnswer">
        <v-col
          :key="data.answer"
          style="text-align: initial"
          :class="i % 2 == 0 ? 'border-right-f' : ''"
          class="pl-0"
          cols="6"
          md="6"
          xs="6"
          sm="6"
          lg="6"
          xl="6"
        >
          <label class="text-label-dark-o"> {{ data.question }} </label> :

          <template v-if="Array.isArray(data.answers)">
            <br :key="'a' + data.answer" />
            <br :key="'a1' + data.answer" />
            <template v-for="data1 in data.answers">
              <label :key="data1" class="text-label-o pl-8">
                <v-icon color="#466be3"> mdi-circle-medium </v-icon> {{ data1 }}
              </label>
              <br :key="'o' + data1" />
            </template>
          </template>

          <template v-else>
            <label class="text-label-o"> {{ data.answers }} </label>
          </template>
        </v-col>
      </template>
    </v-row> -->

    <!-- mobile -->
    <!-- <h1 class="text-title text-left" style="padding-bottom: 21px">
        {{ title }}
      </h1> -->
    <!-- <template v-for="(data, i) in listAnswer">
        <v-col
          :key="data.answer"
          style="text-align: initial"
          class="mb-sm-answer desktop-hidden"
        >
          <label class="text-label-dark-o" style="padding-bottom: 25px">
            {{ data.question }}
          </label>
          :

          <template v-if="Array.isArray(data.answers)">
            <br :key="'a' + data.answer" />
            <br :key="'a1' + data.answer" />
            <template v-for="data1 in data.answers">
              <label :key="data1" class="text-label-o pl-8">
                <v-icon color="#466be3"> mdi-circle-medium </v-icon> {{ data1 }}
              </label>
              <br :key="'o' + data1" />
            </template>
          </template>

          <template v-else>
            <label class="text-label-o"> {{ data.answers }} </label>
          </template>
        </v-col>
      </template>
    </v-row> -->

    <v-snackbar
      center
      outlined
      :color="alert.color"
      v-model="alert.show"
      timeout="5000"
    >
      <b style="font-size: 15px">{{ alert.text }}</b>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          fab
          icon
          @click="alert.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>

    <!-- <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <img :src="documentUrl" class="imageRes" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            text
            dark
            rounded
            class="btn-main"
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialog" width="450" content-class="dialog-image">
      <img :src="documentUrl" class="imageRes" />
    </v-dialog>
  </div>
</template>
<script>
import { TYPEFORM } from "@/utils/util.js";
import Api from "@/utils/api";

export default {
  data() {
    return {
      answers: null,
      email: null,
      modalSend: false,
      formId: null,
      document: null,
      title: null,
      alert: {
        show: false,
        text: null,
        color: null,
      },
      typeForm: TYPEFORM,
      show1: [],
      listAnswer: [],
      menu: false,
      loading: {
        all: false,
      },
      name: "",
      dialog: false,
      documentUrl: "",
    };
  },
  methods: {
    showDocument(data){
      if(data.name.includes("file-upload")){
        window.open(data.answers,'_blank');
      } else {
        this.dialog = true;
        this.documentUrl = data.answers;
      }
    },
    getToken() {
      let token = localStorage.getItem("token");
      let div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        let nameList = this.infoToken.fullName.split(" ");
        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getForms() {
      this.loading.all = true;
      Api.noAuth()
        .getFormsQuestions(this.formId)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading.all = false;
          // console.log(data);
          this.show1 = data.data[0].questions;
          this.title = data.data[0].title;
          this.getFormsAnswer();
        })
        .catch((error) => {
          this.loading.all = false;
          console.log(error);
        });
    },
    getFormsAnswer() {
      this.loading.all = true;
      Api.noAuth()
        .getFormsAnswerId(this.document, this.formId)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading.all = false;
          let lastIndex = data.data.length - 1;
          this.answers = data.data[lastIndex].answers;
          // this.answers = data.data[0].answers;
          var r = [];
          for (let i = 0; i < this.show1.length; i++) {
            if (
              this.show1[i].type != TYPEFORM.HTML &&
              this.show1[i].type != TYPEFORM.SUBMIT &&
              this.show1[i].type != TYPEFORM.SECTION_BREAK &&
              this.show1[i].type != TYPEFORM.HIDDEN
            ) {
              if (this.answers[this.show1[i].name]) {
                r.push({
                  question: this.show1[i].question_label,
                  answers:
                    this.answers[this.show1[i].name].length === 0
                      ? this.answers[this.show1[i].name + "_url"]
                      : this.answers[this.show1[i].name],
                  type: this.show1[i].type,
                  name: this.show1[i].name
                });
              }
            }
          }
          this.listAnswer = r;
        })
        .catch((error) => {
          this.loading.all = false;
          console.log(error);
        });
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.formId = this.$route.params.id;
    this.document = sessionStorage.getItem("document_number");
    //this.getFormsAnswer()
    this.getForms();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.imageRes {
  /* width: auto !important;
  max-width: 100%;
  height: 400px !important; */
  max-height: 90% !important;
  max-width: 90% !important;
  border-radius: 10px;
}

.dialog-image {
  box-shadow: none !important;
  overflow-y: inherit !important;
}

.break-word {
  word-break: break-word;
}

.mdi-asterisk::before {
  content: "\F06C4";
  font-size: 15px;
}

.border-right-f {
  text-align: initial;
  border-right: 2px solid;
}

.text-label-dark-o {
  color: #2e3e75 !important;
  text-align: initial;
  font-size: 20px;
  margin-bottom: 5px !important;
  font-weight: bold;
}

.text-label-o {
  color: #898989 !important;
  text-align: initial;
  font-size: 18px;
  margin-bottom: 10px !important;
}

.mb-xl-formsanswers {
  display: flex;
}
.desktop-hidden {
  display: none !important;
}
.text-title-ligth {
  line-height: 50px !important;
}

.mb-t-formsanswers {
  display: none !important;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
  font-family: "proximaNovaRegular";
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "AsapRegular";
}

.hidden {
  display: "none";
}

.forms-answers__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

/* LAPTOP 4k */
@media (max-width: 1900px) {
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
}

/* TABLET */
@media (max-width: 1023px) {
  .mb-xl-formsanswers {
    display: none !important;
  }

  .mb-t-formsanswers {
    display: flex !important;
  }
  .mb-sm-answer {
    display: none;
  }

  .mb-sm-formsanswers {
    display: none !important;
  }

  .txt-title-mov {
    font-family: "Asap";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;

    /* Complementarios/gris 4 */

    color: #626262;
  }

  .txt-hola-title {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }

  .spacing-form-res {
    padding-bottom: 60px;
  }
}

/* MOBILE L - 767px*/
@media (max-width: 767px) {
  .mb-xl-formsanswers {
    display: none !important;
  }

  .mb-t-formsanswers {
    display: none;
  }

  .mb-sm-answer {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
  }

  .text-title-xl {
    color: #466be3 !important;
  }

  .txt-title-mov {
    font-family: "Asap";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;

    /* Complementarios/gris 4 */

    color: #626262;
  }

  .txt-hola-title {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }

  .border-right-f {
    border-right: 0px;
  }
}
/* MOBILE M */
@media (max-width: 375px) {
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
